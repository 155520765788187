import React from 'react';

import {
    overwrite,
    section as sectionClass,
    grid,
    title as titleClass,
    description as descriptionClass,
    subtitle as subtitleClass,
    button as buttonClass,
    socialsContainer,
} from './plain-social.module.scss';
import { ISection, ISectionContentButton } from '../../models/section.model';
import { ISocialLink } from '../../models/social-link.model';

import Section from '../hoc/section';
import SocialLink from '../atoms/social-link';

export interface IPlainSocialSection extends ISection {
    items: {
        socials: ISocialLink[];
    };
}

interface IPlainSocialProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: IPlainSocialSection;
}

export default function PlainSocial({ className, TitleTag, section }: IPlainSocialProps) {
    const { sectionId, content, css, style, items } = section;
    const { texts, buttons } = content;
    const { socials } = items;
    const [title, subtitle, description] = texts;
    const button: Partial<ISectionContentButton> = buttons[0] ?? {};

    return (
        <Section
            sectionId={sectionId}
            className={`${sectionClass} ${className} ${overwrite}`}
            classes={{
                container: grid,
                title: titleClass,
                description: descriptionClass,
                subtitle: subtitleClass,
                button: buttonClass,
            }}
            TitleTag={TitleTag}
            title={title}
            subtitle={subtitle}
            description={description}
            buttonText={button.text}
            buttonUrl={button.url}
            css={css}
            style={style}
        >
            <div className={socialsContainer}>
                {socials.map((link, i) => {
                    return <SocialLink socialLink={link} key={`social-${i}`} />;
                })}
            </div>
        </Section>
    );
}
